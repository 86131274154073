/**
 * Config object to be passed to MSAL on creation.
 * For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 * */
const msalConfig = {
  auth: {
    authority: window._env_.REACT_APP_MSAL_AUTHORITY ?? "",
    clientId: window._env_.REACT_APP_MSAL_CLIENT_ID ?? "",
    redirectUri: "/",
    postLogoutRedirectUri: window._env_.REACT_APP_MSAL_POST_LOGOUT_URL ?? "/", // Indicates the page to navigate after logout.
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const loginRequest = {
  scopes: (window._env_.REACT_APP_MSAL_LOGIN_SCOPES ?? "").split(" "),
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
const tokenRequest = {
  scopes: (window._env_.REACT_APP_MSAL_ACCESS_TOKEN_SCOPES ?? "").split(" "),
};

export { msalConfig, loginRequest, tokenRequest };
