import React from "react";
import styled from "styled-components";
import { Table as BaseTable } from "semantic-ui-react";
import { prop } from "src/theme";

const StyledTable = styled(BaseTable).attrs({
  basic: "very",
  unstackable: true,
})`
  &&&&&& {
    th {
      color: ${prop("colors.extraDarkGrey")};
      border-bottom: 2px solid ${prop("colors.brandy")};
      padding-bottom: 5px;
      padding-left: 7px;
    }
    tr td {
      border-top-width: ${(props) => (props.borderless ? 0 : "1px")};
      padding-left: 7px;
    }
    tfoot {
      background: ${prop("colors.ultraLightGrey")};
      td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 7px;
      }
    }
  }
`;
// eslint-disable-next-line
export default (props) => <StyledTable {...props} />;

export const Row = (props) => <BaseTable.Row {...props} />;

export const Cell = (props) => <BaseTable.Cell {...props} />;

export const HeaderCell = (props) => <BaseTable.HeaderCell {...props} />;

export const TableHeader = (props) => <BaseTable.Header {...props} />;

export const TableBody = (props) => <BaseTable.Body {...props} />;

export const TableFooter = (props) => <BaseTable.Footer {...props} />;
