import { useEffect, useRef } from "react";

export const useInactivityTimer = (timeout) => {
  const timerId = useRef(null);

  // Reset the inactivity timer
  const resetTimer = () => {
    if (timerId.current) clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      window.location.reload();
    }, timeout);
  };

  useEffect(() => {
    const activityEvents = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];

    activityEvents.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      activityEvents.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timerId.current) clearTimeout(timerId.current);
    }; // eslint-disable-next-line
  }, [timeout]);

  return null;
};
