import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { prop } from "src/theme";

const defaultButtonStyle = css`
  padding: 10px 14px;
  font-size: 14px;
  @media (min-width: 700px) {
    padding: 10px 16px;
    font-size: 16px;
  }
`;
const smallButtonStyle = css`
  padding: 5px 5px;
  font-size: 12px;
  @media (min-width: 700px) {
    padding: 5px 10px;
    font-size: 14px;
  }
`;

export const buttonStyles = css`
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background: ${(props) =>
    prop(
      props.buttontype === "primary"
        ? "button.primaryBackground"
        : props.buttontype === "secondary"
        ? "button.secondaryBackground"
        : "button.background"
    )};
  color: ${(props) =>
    prop(
      props.buttontype === "primary"
        ? "button.primaryColor"
        : props.buttontype === "secondary"
        ? "button.secondaryColor"
        : "button.color"
    )};
  border: 1px solid
    ${(props) =>
      prop(
        props.buttontype === "primary"
          ? "button.primaryBackground"
          : props.buttontype === "secondary"
          ? "button.secondaryColor"
          : "button.background"
      )};

  border-radius: 4px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  transition: background-color 0.1s ease;
  outline: none;
  ${(props) => (props.size === "small" ? smallButtonStyle : defaultButtonStyle)}
  &:disabled {
    color: ${prop("button.disabledColor")};
    border-color: ${prop("button.disabledBorder")};
    background-color: ${prop("button.disabledBackground")};
  }
  &:not(:disabled):hover {
    background: ${(props) =>
      prop(props.buttontype === "primary" ? "button.primaryHoverBackground" : "button.secondaryBackground")};
    color: ${(props) => prop(props.buttontype === "primary" ? "button.primaryHover" : "button.secondaryColor")};
    border-color: ${(props) =>
      prop(props.buttontype === "primary" ? "button.primaryHoverBackground" : "button.secondaryColor")};
  }
  & + button {
    margin-left: 1rem;
  }
`;
const Button = styled.button`
  ${buttonStyles}
`;

Button.propTypes = {
  size: PropTypes.oneOf(["small", "default"]),
  buttontype: PropTypes.string,
};
Button.defaultProps = {
  size: "default",
  buttontype: "primary",
};
export default Button;
