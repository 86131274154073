import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import nl from "./translations/nl/nl.json";
import fr from "./translations/fr/fr.json";
import en from "./translations/en/en.json";

export const LANGUAGES = ["en"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whitelist: LANGUAGES,
    checkWhitelist: true,
    resources: {
      nl,
      fr,
      en,
    },
    debug: window._env_.NODE_ENV !== "production",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
