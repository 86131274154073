import React from "react";
import Subtitle from "src/components/common/ui/Subtitle";
import { useTranslation } from "react-i18next";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Brush, ReferenceLine } from "recharts";
import { format } from "date-fns";
import styled from "styled-components";
import { prop } from "../../theme";

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;
const DailyCounts = ({ counts }) => {
  const { t } = useTranslation();
  const dailyCount = counts.map((count) => ({ ...count, date: format(count.date, "MMMM dd") }));

  return (
    <>
      <Subtitle>{t("insurerMetrics.dailyCount.title")}</Subtitle>
      {counts.length === 0 ? (
        <NoData>{t("transactions.form.noData")}</NoData>
      ) : (
        <BarChart
          width={700}
          height={400}
          data={dailyCount}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Brush dataKey="date" height={30} stroke="#A0A0A0" />
          <Bar dataKey="successes" fill="#009900" background={{ fill: "#F5F5F5" }} />
          <Bar dataKey="failures" fill="#CC0000" background={{ fill: "#F5F5F5" }} />
        </BarChart>
      )}
    </>
  );
};

export default DailyCounts;
