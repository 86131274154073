import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import moment from "moment";

import { Form, Formik } from "formik";
import Fieldset from "src/components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "src/components/common/form/SelectField";
import InputField from "src/components/common/form/InputField";
import DatePickerField from "src/components/common/form/DatePickerField/DatePickerField";
import Button from "src/components/common/ui/Button";
import searchFormSchema from "./searchFormSchema";
import { addYears } from "date-fns";
import { createActorOptions } from "src/helpers/helpers";
import { Link } from "react-router-dom";
import Pagination from "src/components/common/ui/Pagination";
import SortableTableHeader from "src/components/common/ui/SortableTableHeader";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const iniitialSortValues = {
  label: undefined,
  order: "desc",
};
const iniitialPageValues = {
  number: 1,
  pages: 3,
};
const initialSearchValues = {
  insuranceActorBNB: "all",
  policyNumber: "",
  licensePlate: "",
  vin: "",
  startDate: new Date(),
  endDate: new Date(),
};

const Div = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState();
  const [searchResp, setSearchResp] = useState();
  const [actors, setActors] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [pageState, setPageState] = useState({
    ...iniitialPageValues,
    ...location?.state?.page,
  });
  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });
  const [sortState, setSortState] = useState({
    ...iniitialSortValues,
    ...location?.state?.sort,
  });

  const minLoadDate = addYears(new Date(), -7);
  const maxLoadDate = addYears(new Date(), 1);
  const { accessToken } = useContext(AuthContext);

  const api = new ApiService();

  const handleSubmit = async (val) => {
    try {
      val.licensePlate = val.licensePlate.toUpperCase().trim();
      val.vin = val.vin.toUpperCase().trim();
      setSearchValues({ ...initialSearchValues, ...val });
      await searchDivPolicy(val, pageState, sortState);
    } catch (e) {
      console.log(e);
    }
  };
  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setSortState(iniitialSortValues);
    setPageState(1);
    setError(null);
    setSearchResp(undefined);
  };

  const searchDivPolicy = async (values, page, sort) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.searchDivPolicy(accessToken, values, page, sort);
      setSearchResp(resp.data);
      setSubmitting(false);
      setPageState(resp?.page || iniitialPageValues);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const updatePage = async (page) => {
    const newPageState = {
      ...pageState,
      number: page,
    };
    setPageState(newPageState);
    await searchDivPolicy(searchValues, newPageState, sortState);
  };
  const updateSort = async (sort) => {
    setSortState(sort);
    await searchDivPolicy(searchValues, pageState, sort);
  };

  const getActors = async () => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.getActor(accessToken);
      setActors(resp.data);
      setSubmitting(false);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    getActors();
    if (location.state) {
      searchDivPolicy(searchValues, pageState, sortState);
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation />
      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={searchFormSchema(t)}>
        {({ values, resetForm, isSubmitting, setValues }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="insuranceActorBNB"
                      label={t("div.form.label.insuranceActorBNB")}
                      value={values.insuranceActorBNB}
                      options={createActorOptions(actors)}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="licensePlate"
                      label={t("div.form.label.plate")}
                      type="text"
                      value={values.licensePlate?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="policyNumber"
                      label={t("div.form.label.policyNumber")}
                      type="text"
                      value={values.policyNumber}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="vin"
                      label={t("div.form.label.vin")}
                      type="text"
                      value={values.vin?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDate"
                      label={t("div.form.label.startDate")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDate"
                      label={t("div.form.label.endDate")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                </Flex>
                <Flex>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttontype="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("div.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit" onClick={() => setPageState(1)}>
                      <SearchIcon />
                      {t("div.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        searchResp && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("div.table.header.insurerBNB")}</HeaderCell>
                  <HeaderCell width="1">{t("div.table.header.policy")}</HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"licensePlate"} sortState={sortState} setSortState={updateSort}>
                      {t("div.table.header.plate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"vin"} sortState={sortState} setSortState={updateSort}>
                      {t("div.table.header.vin")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"startDate"} sortState={sortState} setSortState={updateSort}>
                      {t("div.table.header.startDate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"endDate"} sortState={sortState} setSortState={updateSort}>
                      {t("div.table.header.endDate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">{t("div.table.header.suspension")}</HeaderCell>

                  <HeaderCell width="1">
                    <SortableTableHeader label={"modelName"} sortState={sortState} setSortState={updateSort}>
                      {t("div.table.header.car")}
                    </SortableTableHeader>
                  </HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {searchResp.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("div.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  searchResp.map((policy, index) => {
                    return (
                      <Row key={index}>
                        <Cell>{policy?.actor?.bnb}</Cell>
                        <Cell>{policy?.insurancePolicy?.policyNumber}</Cell>
                        <Cell>
                          <Link
                            to={{
                              pathname: `/policy/${policy?.insuredObject?.identifier}`,
                              state: {
                                url: "/div",
                                values: searchValues,
                                page: pageState,
                                sort: sortState,
                              },
                            }}
                          >
                            {policy?.insuredObject?.licensePlate}
                          </Link>
                        </Cell>
                        <Cell>
                          <Link
                            to={{
                              pathname: `/policy/${policy?.insuredObject?.identifier}`,
                              state: {
                                url: "/div",
                                values: searchValues,
                                page: pageState,
                                sort: sortState,
                              },
                            }}
                          >
                            {policy?.insuredObject?.vin}
                          </Link>
                        </Cell>
                        <Cell>{moment(policy?.coverage?.startDate)?.format("DD/MM/YYYY")}</Cell>
                        <Cell>
                          {policy?.coverage?.endDate && moment(policy?.coverage?.endDate)?.format("DD/MM/YYYY")}
                        </Cell>
                        <Cell>{policy?.coverage?.suspensionType}</Cell>
                        <Cell>
                          {policy?.insuredObject?.makeName} {policy?.insuredObject?.modelName}
                        </Cell>
                      </Row>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <Pagination onChange={(page) => updatePage(page)} number={pageState?.number} pages={pageState?.pages} />
          </>
        )
      )}
    </>
  );
};
export default Div;
