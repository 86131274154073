import styled from "styled-components";

import { prop } from "src/theme";

const Subtitle = styled.h3`
  display: block;
  color: ${prop("colors.lightBrandy")};
  font-size: 22px;
  font-weight: bold;
  z-index: 0;
  margin: 20px 0 20px 0;
  width: 100%;
`;

export default Subtitle;
