/**
 * Create a list of unique model_name values for a particular make.
 * Filter out undefined values as they are pretty common.
 *
 * @param actors: list of actor objects containing make, model, type tuples
 * @returns {{label: *, value: *}[]}
 */
export const createActorOptions = (actors) => {
  const result = actors
    .filter((actor) => actor !== undefined)
    .map((actor) => {
      return {
        value: actor.bnb,
        label: `${actor.bnb} - ${actor.name}`,
      };
    });
  return [{ label: "All", value: "all" }, ...result];
};

/**
 * Create a list of unique file status values
 *
 * @returns {{label: *, value: *}[]}
 */

export const createFileStatusOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },

    {
      label: "Started",
      value: "STARTED",
    },
    {
      label: "Finished",
      value: "FINISHED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
    {
      label: "Failed_Ack",
      value: "FAILED_ACK",
    },
  ];
};

/**
 * Create a list of unique origins values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createOriginOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Public website",
      value: "WEBSITE",
    },
    {
      label: "Insurer",
      value: "INSURER",
    },
  ];
};
/**
 * Create a list of unique file type values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createFileTypeOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Load",
      value: "FILE_LOAD",
    },
    {
      label: "Extract",
      value: "FILE_EXTRACT",
    },
  ];
};
