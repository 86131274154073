import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";
import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const TableWrapper = styled.div`
  margin-top: 2rem;
`;

export default function StatusSector() {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [sector, setSector] = useState();
  const [isSubmitting, setSubmitting] = useState(false);

  const { accessToken } = useContext(AuthContext);

  const api = new ApiService();
  const getSectorRepotings = async () => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.getSector(accessToken);
      setSector(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
      throw e;
    }
  };

  useEffect(() => {
    getSectorRepotings().catch((e) => {
      console.log(e);
    }); // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation />
      <TableWrapper>
        {error ? (
          <ErrorMessage message={error} />
        ) : isSubmitting ? (
          <Spinner />
        ) : (
          sector && (
            <>
              <Table>
                <TableHeader>
                  <Row>
                    <HeaderCell width="1">{t("sector.table.header.insurerBNB")}</HeaderCell>
                    <HeaderCell width="1">{t("sector.table.header.company")}</HeaderCell>
                    <HeaderCell width="1">{t("sector.table.header.policy")}</HeaderCell>
                    <HeaderCell width="1">{t("sector.table.header.insuredObject")}</HeaderCell>
                    <HeaderCell width="1">{t("sector.table.header.activeCoverages")}</HeaderCell>
                    <HeaderCell width="1">{t("sector.table.header.inactiveCoverages")}</HeaderCell>
                  </Row>
                </TableHeader>

                <TableBody>
                  {sector.length === 0 ? (
                    <Row textAlign={"center"}>
                      <Cell colSpan={11}>
                        <NoData>{t("vehicleSearch.form.noData")}</NoData>
                      </Cell>
                    </Row>
                  ) : (
                    sector.map((sectorItem, index) => (
                      <Row key={index}>
                        <Cell>{sectorItem.actor?.bnb}</Cell>
                        <Cell>{sectorItem.actor?.name}</Cell>
                        <Cell textAlign={"right"}>
                          <NumericFormat
                            value={sectorItem.actor?.numberOfObjects?.policies}
                            displayType={"text"}
                            thousandSeparator={" "}
                          />
                        </Cell>
                        <Cell textAlign={"right"}>
                          <NumericFormat
                            value={sectorItem.actor?.numberOfObjects?.insuredObjects}
                            displayType={"text"}
                            thousandSeparator={" "}
                          />
                        </Cell>
                        <Cell textAlign={"right"}>
                          <NumericFormat
                            value={sectorItem.actor?.numberOfObjects?.activeCoverages}
                            displayType={"text"}
                            thousandSeparator={" "}
                          />
                        </Cell>
                        <Cell textAlign={"right"}>
                          <NumericFormat
                            value={sectorItem.actor?.numberOfObjects?.inactiveCoverages}
                            displayType={"text"}
                            thousandSeparator={" "}
                          />
                        </Cell>
                      </Row>
                    ))
                  )}
                </TableBody>
              </Table>
            </>
          )
        )}
      </TableWrapper>
    </>
  );
}
