import { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import { Icon } from "semantic-ui-react";
import { BackEndError } from "src/services/ApiService";
import { prop } from "src/theme";

import logoSrc from "./exclamation.svg";
import { useTranslation } from "react-i18next";
import Divider from "../Divider/Divider";

const Logo = styled.img.attrs({ src: logoSrc })`
  height: 32px;
  svg {
    fill: green;
  }
  fill: green;
`;

const Wrapper = styled(Flex)`
  border: 2px solid ${prop("colors.brandy")};
  color: ${prop("colors.brandy")};
  font-size: 1.2rem;
  border-radius: 4px;
  padding: 12px;
  margin-top: 10px;
  align-items: flex-start;
  flex-direction: column;
`;

const BackendErrorWrapper = styled(Flex)`
  border: 2px solid ${prop("colors.brandy")};
  color: ${prop("colors.brandy")};
  font-size: 1.2rem;
  border-radius: 4px;
  padding: 12px;
  margin-top: 10px;
  align-items: center;
`;

const TitleWrapper = styled(Flex)`
  align-items: center;
`;

const ContentWrapper = styled(Box)`
  margin: 1em 0 1em 2.75em;
`;

export default function ErrorMessage({ message: error }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const isBackendError = BackEndError?.isInstance(error);
  const toggleOpen = () => {
    setOpen(!open);
  };

  return isBackendError ? (
    <BackendErrorWrapper>
      <Logo />
      <div> {error.toString()}</div>
    </BackendErrorWrapper>
  ) : (
    <Wrapper>
      <TitleWrapper>
        <Logo />
        {t("errorCodes.generalError")}
        <Icon name={open ? "triangle up" : "triangle down"} onClick={() => toggleOpen()} />
      </TitleWrapper>
      {open && (
        <ContentWrapper>
          <Divider />
          <div> {error.toString()}</div>
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
