import React from "react";
import { Pagination as BasePagination } from "semantic-ui-react";
import styled, { createGlobalStyle } from "styled-components";
import { Icon } from "semantic-ui-react";

import { prop } from "src/theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const GlobalStyle = createGlobalStyle`
  .ui.secondary.menu .item:focus {
    outline: none;
  }
  .ui.secondary.menu .item {
    font-weight: bold;
    color: ${prop("colors.extraDarkGrey")};
    justify-content: center;
  }
  .ui.secondary.menu .active.item, .ui.secondary.menu .active.item:hover {
    background-color: transparent;
    color: ${prop("colors.brandy")};
    padding: 11px 0;
  }
  .ui.secondary.menu a.item:hover {
    background-color: transparent;
  }
  .ui.secondary.menu a[type=pageItem] {
    padding: 11px 0;
    margin-left: 0;
    margin-right: 0;
  }
`;

export default function Pagination({ onChange, number = 1, pages = 1 }) {
  if (pages <= 1) {
    return null;
  }
  return (
    <Wrapper>
      <GlobalStyle />
      <BasePagination
        defaultActivePage={number}
        totalPages={pages}
        firstItem={null}
        lastItem={null}
        secondary
        prevItem={{
          content: number > 1 ? <Icon name={"triangle left"} /> : null,
        }}
        nextItem={{
          content: number < pages ? <Icon name={"triangle right"} /> : null,
        }}
        onPageChange={(e, { activePage }) => onChange(activePage)}
      />
    </Wrapper>
  );
}
