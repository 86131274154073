import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { prop } from "src/theme";

const Container = styled.div`
  ${(props) => !props.hidden && `margin: 0 0 20px 0`};
`;
const Label = styled.label`
  display: block;
  font-size: ${prop("input.fontSize")};
  margin-bottom: 1px;
  color: ${(props) => prop(props.disabled ? "colors.lightGrey" : "colors.extraDarkGrey")(props)};
`;
const Explanation = styled.div`
  margin: 5px 0 0 0;
  font-size: 16px;
`;
const Error = styled.div`
  font-size: 16px;
  color: ${prop("colors.brandy")};
  margin: 5px 0 0 0;
`;
const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;
export const propTypes = {
  label: PropTypes.string.isRequired,
  labelPrefix: PropTypes.node,
  labelSuffix: PropTypes.node,
  id: PropTypes.string,
  explanation: PropTypes.node,
  error: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.any,
};

export default class Field extends Component {
  static propTypes = propTypes;
  static defaultProps = {
    labelPrefix: "",
    labelSuffix: "",
  };
  render() {
    const { label, labelPrefix, labelSuffix, id, explanation, error, type, children, disabled } = this.props;
    return (
      <Container hidden={type === "hidden" && !error}>
        <LabelContainer>
          <Label htmlFor={id} disabled={disabled}>
            {labelPrefix}
            {label}
            {labelSuffix}
          </Label>
        </LabelContainer>
        {children}
        {!error && explanation && <Explanation>{explanation}</Explanation>}
        {error && <Error>{error}</Error>}
      </Container>
    );
  }
}
