import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table/Table";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TableSubHeader = styled.div`
  font-weight: bold;
`;

const TitleCell = styled(HeaderCell)`
  font-size: large;
`;

export default function PolicyAndHolderTable({ insuredObject }) {
  const { t } = useTranslation();
  return (
    <Table fixed>
      <TableHeader>
        <Row>
          <TitleCell colSpan="4" textAlign={"center"}>
            {t("policyDetails.table.policyAndHolder.title")}
          </TitleCell>
        </Row>
      </TableHeader>
      <TableBody>
        <Row key={0}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.policyNr")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.policyNumber}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.lastName")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuranceHolder?.lastName}</Cell>
        </Row>
        <Row key={1}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.fleet")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.fleetVehicleIndicator}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.firstName")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuranceHolder?.firstName}</Cell>
        </Row>
        <Row key={2}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.veridassPolicyId")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.veridassIdentifier}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.address")}</TableSubHeader>
          </Cell>
          <Cell>
            {insuredObject?.insuranceHolder?.streetName} {insuredObject?.insuranceHolder?.houseNumber}
          </Cell>
        </Row>
        <Row key={3}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.veridassInsHolderId")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.insuranceHolder?.veridassIdentifier}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.city")}</TableSubHeader>
          </Cell>
          <Cell>
            {insuredObject?.insuranceHolder?.postalCode} {insuredObject?.insuranceHolder?.cityName}
          </Cell>
        </Row>
        <Row key={4}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.poiPolicyId")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.identifier}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.country")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuranceHolder?.countryCode}</Cell>
        </Row>
        <Row key={5}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.poiInsHolderId")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject.insuranceHolder?.identifier}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.policyAndHolder.subHeaders.language")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuranceHolder?.languageCode}</Cell>
        </Row>
      </TableBody>
    </Table>
  );
}
