import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape(
    {
      bnb: yup.string().required(t("insurerMetrics.form.validation.bnb")),
      source: yup.string("Not a string."),
      startDateTime: yup.date().required(),
      endDateTime: yup
        .date()
        .required()
        .when("startDateTime", (eventstartDateTime, schema) => eventstartDateTime && schema.min(eventstartDateTime)),
    },
    [["source", "bnb", "startDateTime", "endDateTime"]]
  );
};
