import React from "react";
import { Routes, Route } from "react-router-dom";

import StatusSector from "src/pages/StatusSector/StatusSector";
import PolicySearch from "src/pages/PolicySearch/PolicySearch";
import Metrics from "src/pages/Metrics/Metrics";
import PolicyDetails from "src/pages/PolicySearch/PolicyDetails";
import Home from "src/pages/Home";
import File from "src/pages/File";
import Transactions from "src/pages/Transactions";
import InsurerMetrics from "src/pages/InsurerMetrics/InsurerMetrics";
import Div from "src/pages/Div";
import Audit from "src/pages/Audit";

const PageRoutes = () => {
  return (
    <Routes>
      <Route exact path={"/"} element={<Home />} />
      <Route exact path={"/file"} element={<File />} />
      <Route exact path={"/transactions"} element={<Transactions />} />
      <Route exact path={"/policy"} element={<PolicySearch />} />
      <Route exact path={"/sector"} element={<StatusSector />} />
      <Route path={"/policy/:insureObjectId"} element={<PolicyDetails />} />
      <Route exact path={"/metrics"} element={<Metrics />} />
      <Route exact path={"/insurer-metrics"} element={<InsurerMetrics />} />
      <Route exact path={"/div"} element={<Div />} />
      <Route exact path={"/audit"} element={<Audit />} />
    </Routes>
  );
};

export default PageRoutes;
