/**
 * Create a list of source values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createSourceOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },
    {
      label: "File",
      value: "FILE",
    },
    {
      label: "API",
      value: "API",
    },
  ];
};
/**
 * Create a list of unique model_name values for a particular make.
 * Filter out undefined values as they are pretty common.
 *
 * @param actors: list of actor objects containing make, model, type tuples
 * @returns {{label: *, value: *}[]}
 */
export const createActorOptions = (actors) => {
  const result = actors
    .filter((actor) => actor !== undefined)
    .map((actor) => {
      return {
        value: actor.bnb,
        label: `${actor.bnb} - ${actor.name}`,
      };
    });
  return [{ label: "", value: "" }, ...result];
};
