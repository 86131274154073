import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "./i18n";
import theme from "./theme";
import GlobalStyle from "./GlobalStyle";
import Container from "src/components/Container";

import PageRoutes from "./pages/routes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`;

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Main>
          <Container>
            <PageRoutes />
          </Container>
        </Main>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
