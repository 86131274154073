import { useEffect, createContext, useState } from "react";
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import styled from "styled-components";

import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { login, logout } from "./authService";

import Spinner from "src/components/common/ui/Spinner";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import { useInactivityTimer } from "./userInactivityHook";

const Wrapper = styled.div`
  padding: 50px;
`;

const AuthError = ({ error }) => {
  return (
    <Wrapper>
      <ErrorMessage message={error?.message} debug={error?.debug} />
    </Wrapper>
  );
};

const AuthSpinner = () => {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

export const AuthContext = createContext("Auth");

const AuthProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  const [accessToken, setAccessToken] = useState();
  const [error, setError] = useState();

  const accessTokenRequest = {
    scopes: [window._env_.REACT_APP_MSAL_LOGIN_SCOPES],
    account: accounts[0],
  };

  useInactivityTimer(window._env_.REACT_APP_USER_INACTIVITY_MINUTES * 60 * 1000);
  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((tokenResponse) => {
          setAccessToken(`Bearer ${tokenResponse.accessToken}`);
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
            // fallback to interaction when silent call fails
            const tokenResponse = await instance.acquireTokenRedirect(accessTokenRequest);
            setAccessToken(`Bearer ${tokenResponse.accessToken}`);
          }
          setError(e);
        });
    } // eslint-disable-next-line
  }, [isAuthenticated]);

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    login(instance);
  }

  if (accessToken === undefined) {
    login(instance);
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        logout: () => logout(instance),
        accessToken: accessToken,
        error: error,
      }}
    >
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={accessTokenRequest}
        errorComponent={AuthError}
        loadingComponent={AuthSpinner}
      >
        {children}
      </MsalAuthenticationTemplate>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
