import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Title from "src/components/common/ui/Title";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";
import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import { createActorOptions, createFileTypeOptions, createFileStatusOptions } from "src/helpers/helpers";
import { Form, Formik } from "formik";
import Fieldset from "src/components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "src/components/common/form/SelectField";
import DatePickerField from "src/components/common/form/DatePickerField/DatePickerField";
import Button from "src/components/common/ui/Button";
import { addYears } from "date-fns";
import { Link } from "react-router-dom";
import fileSchema from "./fileSchema";
import moment from "moment";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;
const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const initialSearchValues = {
  bnb: "all",
  status: "all",
  type: "all",
  startDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
  endDateTime: new Date(new Date().setHours(23, 59, 59, 999)),
};
const File = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { accessToken } = useContext(AuthContext);
  const minLoadDate = addYears(new Date(), -window._env_?.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_PAST);
  const maxLoadDate = addYears(new Date(), window._env_?.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_FUTUR);

  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [files, setFiles] = useState();
  const [actors, setActors] = useState([]);

  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });

  const api = new ApiService();

  const handleSubmit = async (values) => {
    try {
      setSearchValues({ ...initialSearchValues, ...values });
      await searchFiles(values);
    } catch (e) {
      console.log(e);
    }
  };

  const searchFiles = async (values) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.getFiles(accessToken, values);
      setFiles(resp.data);
      setSubmitting(false);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const getActors = async () => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.getActor(accessToken);
      setActors(resp.data);
      setSubmitting(false);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setError(null);
    setFiles(undefined);
  };
  useEffect(() => {
    getActors();
    if (location.state) {
      searchFiles(searchValues);
    } // eslint-disable-next-line
  }, []);
  return (
    <>
      <Navigation />
      <Title>{t("files.title")}</Title>

      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={fileSchema(t)}>
        {({ values, resetForm, isSubmitting, setValues }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="bnb"
                      label={t("files.form.label.bnb")}
                      value={values.bnb}
                      options={createActorOptions(actors)}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <SelectField
                      name="status"
                      label={t("files.form.label.status")}
                      value={values.status}
                      options={createFileStatusOptions()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <SelectField
                      name="type"
                      label={t("files.form.label.type")}
                      value={values.type}
                      options={createFileTypeOptions()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDateTime"
                      label={t("files.form.label.startDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDateTime"
                      label={t("files.form.label.endDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                </Flex>
                <Flex>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttontype="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("files.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit">
                      <SearchIcon />
                      {t("files.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>

      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        files && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("files.table.header.bnb")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.fileName")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.fileType")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.status")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.comment")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.records")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.failures")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.processingTime")}</HeaderCell>
                  <HeaderCell width="1">{t("files.table.header.createdTmestamp")}</HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {files.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("files.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  files.map((file, index) => {
                    return (
                      <>
                        <Row key={index}>
                          <Cell>{file?.bnb}</Cell>
                          {file?.status === "FINISHED" && file?.type === "FILE_LOAD" ? (
                            <Cell>
                              <Link
                                to={`/transactions`}
                                state={{
                                  values: {
                                    startDateTime: searchValues.startDateTime,
                                    endDateTime: searchValues.endDateTime,
                                    jobId: file.identifier,
                                    bnb: file.bnb,
                                  },
                                }}
                              >
                                {file?.name}
                              </Link>
                            </Cell>
                          ) : (
                            <Cell>{file?.name}</Cell>
                          )}
                          <Cell>{file?.type}</Cell>
                          <Cell>{file?.status}</Cell>
                          <Cell>{file?.comment}</Cell>
                          <Cell textAlign={"right"}>{file?.records}</Cell>
                          <Cell textAlign={"right"}>{file?.failures}</Cell>
                          <Cell>
                            {file?.processingTime && new Date(1000 * file?.processingTime)?.toISOString().substr(11, 8)}
                          </Cell>
                          <Cell>
                            {file?.createdTimestamp && moment(file?.createdTimestamp)?.format("yyyy-MM-DDTHH:mm:ss")}
                          </Cell>
                        </Row>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </>
        )
      )}
    </>
  );
};

export default File;
