import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ApiService from "src/services/ApiService";
import { AuthContext } from "src/auth/AuthProvider";
import Navigation from "src/pages/Navigation";
import { prop } from "src/theme";
import Title from "src/components/common/ui/Title";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";

const StyledCard = styled(Card).attrs({ color: "red" })`
  margin: 0 1em !important;
  a {
    text-decoration: none;
    color: ${prop("colors.black")};
    font-size: inherit;
    font-weight: inherit;
  }
`;

const CardsWrapper = styled.div`
  padding: 3em;
  display: flex;
`;

const Value = styled.div`
  display: flex;
  color: ${prop("colors.lightBrandy")};
  font-size: 5em;
  padding: 0.75em 0 0 0.75em;
`;

const Wrapper = styled.div`
  padding: 50px;
`;

const Home = () => {
  const { t } = useTranslation();

  const api = new ApiService();
  const { accessToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(); // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      if (accessToken) {
        setError(null);
        try {
          setLoading(true);
          const response = await api.getCounters(accessToken);
          setData(response.data);
        } catch (e) {
          setError(e);
        }
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [accessToken]);

  return (
    <>
      {loading ? (
        <Wrapper>
          <Spinner />
        </Wrapper>
      ) : (
        <>
          <Navigation />
          <Title>{t("home.title")}</Title>
          {error ? (
            <ErrorMessage message={error}></ErrorMessage>
          ) : (
            <CardsWrapper>
              <StyledCard>
                <Card.Content>
                  <Card.Header>{t("home.dlq")}</Card.Header>
                  <Value>
                    <Icon name="envelope" />
                    {data.DLQ}
                  </Value>
                </Card.Content>
              </StyledCard>

              <StyledCard>
                <Card.Content>
                  <Card.Header>{t("home.files")}</Card.Header>
                  <Link
                    to={{
                      pathname: `/file`,
                      state: {
                        values: {
                          status: "FAILED",
                          startDateTime: undefined,
                          endDateTime: undefined,
                        },
                      },
                    }}
                  >
                    <Value>
                      <Icon name="file" />
                      {data.failedFiles}
                    </Value>
                  </Link>
                </Card.Content>
              </StyledCard>
            </CardsWrapper>
          )}
        </>
      )}
    </>
  );
};

export default Home;
