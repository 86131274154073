import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { prop } from "src/theme";

const Container = styled.fieldset`
  border: none;
  padding: 1.5rem 0;
  margin: 20px 0 0 0;
  @media (min-width: 500px) {
    margin-top: 20px;
  }
`;
const Legend = styled.h3.attrs({ as: "legend" })`
  color: ${prop("colors.brandy")};
  margin: 0;
  padding: 10px 0 0;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 20px;
  @media (min-width: 500px) {
    margin-top: 20px;
  }
`;

export default class Fieldset extends Component {
  static propTypes = {
    legend: PropTypes.string,
    children: PropTypes.any,
  };
  render() {
    const { legend, children } = this.props;
    return (
      <Container>
        {legend && <Legend>{legend}</Legend>}
        {children}
      </Container>
    );
  }
}
