import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Title from "src/components/common/ui/Title";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon, Modal } from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";
import moment from "moment";
import ReactJson from "react-json-view";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import { createActorOptions } from "../PolicySearch/dropdownOptions";

import { Form, Formik, Field } from "formik";
import Fieldset from "src/components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "src/components/common/form/SelectField";
import InputField from "src/components/common/form/InputField";
import DatePickerField from "src/components/common/form/DatePickerField/DatePickerField";
import Button from "src/components/common/ui/Button";
import { addYears } from "date-fns";
import { Link } from "react-router-dom";
import Pagination from "src/components/common/ui/Pagination";
import Checkbox from "src/components/common/form/Checkbox";
import transactionSearchSchema from "./transactionSearchSchema";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1 / 2] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;
const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const Description = styled.div`
  .react-json-view {
    word-break: break-all;
  }
  .data__value--string {
    word-break: break-all;
    white-space: break-spaces;
  }
  .data__value--object {
    display: none;
  }
`;

const initialPageValues = {
  number: 1,
  pages: 3,
};
const initialSearchValues = {
  bnb: "all",
  policyNumber: "",
  endDateTime: new Date(new Date().setHours(23, 59, 59, 999)),
  startDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
  failed: false,
};
const Transactions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const minLoadDate = addYears(new Date(), -window._env_.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_PAST);
  const maxLoadDate = addYears(new Date(), window._env_.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_FUTUR);

  const { accessToken } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const [actors, setActors] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [transactions, setTransactions] = useState();
  const [input, setInput] = useState();
  const [pageState, setPageState] = useState({
    ...initialPageValues,
    ...location?.state?.page,
  });

  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });
  const api = new ApiService();

  const toggle = (input) => {
    setOpen(!open);
    try {
      setInput(JSON.parse(input));
    } catch (e) {
      console.log(e);
      setInput(input);
    }
  };
  const handleSubmit = async (values) => {
    try {
      values.policyNumber = values.policyNumber.trim();
      setSearchValues({ ...initialSearchValues, ...values });
      await transactionsDetail(values, pageState);
    } catch (e) {
      console.log(e);
    }
  };

  const transactionsDetail = async (values, page) => {
    try {
      setPageState(1);
      setError(null);
      setSubmitting(true);
      const resp = await api.getTransactionDetail(accessToken, values, page);
      setTransactions(resp.data);
      setSubmitting(false);
      setPageState(resp?.page || initialPageValues);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };
  const updatePage = async (page) => {
    const newPageState = {
      ...pageState,
      number: page,
    };
    setPageState(newPageState);
    await transactionsDetail(searchValues, newPageState);
  };

  const getActors = async () => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.getActor(accessToken);
      setActors(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
      // throw e;
    }
  };
  const fetchTransactionsCsv = async () => {
    try {
      setSubmitting(true);
      await api.getTransactionsCsv(accessToken, searchValues);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };

  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setPageState(1);
    setError(null);
    setTransactions(undefined);
  };
  useEffect(() => {
    if (accessToken) {
      getActors();
    }
    if (location.state) {
      transactionsDetail(searchValues, pageState);
    }
    // eslint-disable-next-line
  }, [accessToken]);

  const splitErrors = (errors) => {
    const codes = errors.split(";"); // eslint-disable-next-line
    const errorMessages = codes.map((item) => {
      if (item) {
        return <p>{t(`errorCodes.${item}`)}</p>;
      }
    });
    return errorMessages;
  };

  const replaceErrors = (codes) => {
    if (codes.includes(";")) {
      return codes.replace(/;/g, " ");
    } else {
      return codes;
    }
  };
  return (
    <>
      <Navigation />
      <Title>{t("transactions.title")}</Title>

      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={transactionSearchSchema(t)}>
        {({ values, resetForm, isSubmitting, setValues }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="bnb"
                      label={t("transactions.form.label.bnb")}
                      value={values.bnb}
                      options={createActorOptions(actors)}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="policyNumber"
                      label={t("transactions.form.label.policyNumber")}
                      type="text"
                      value={values.policyNumber}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDateTime"
                      label={t("transactions.form.label.startDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                      showTime
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDateTime"
                      label={t("transactions.form.label.endDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </FieldWrapper>
                  <Field name="failed" type="checkbox" as={Checkbox} label={t("transactions.form.label.failed")} />
                </Flex>
                <Flex>
                  <CsvButtonWrapper>
                    {transactions && transactions.length !== 0 && (
                      <SearchButton type="button" onClick={fetchTransactionsCsv}>
                        <Icon name="external alternate" /> {t("navigation.actions.csv")}
                      </SearchButton>
                    )}
                  </CsvButtonWrapper>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttontype="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("transactions.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit" onClick={() => setPageState(1)}>
                      <SearchIcon />
                      {t("transactions.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>

      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        transactions && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("transactions.table.header.bnb")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.operationEndpoint")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.policyNumber")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.httpVerb")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.status")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.httpResponse")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.functionalErrorCode")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.errorMessage")}</HeaderCell>
                  <HeaderCell width="1">{t("transactions.table.header.timestamp")}</HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {transactions.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("transactions.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  transactions.map((transaction, index) => {
                    return (
                      <>
                        <Row key={index}>
                          <Cell>{transaction?.bnb}</Cell>
                          <Cell>{transaction?.operation ? transaction?.operation : transaction?.endpoint}</Cell>
                          <Cell>
                            <Link
                              to={`/policy`}
                              state={{
                                values: {
                                  insuranceActorBNB: transaction?.bnb,
                                  policyNumber: transaction?.policyNumber,
                                  exactMatch: true,
                                },
                              }}
                            >
                              {transaction?.policyNumber}
                            </Link>
                          </Cell>
                          <Cell>{transaction?.source === "FILE" ? transaction?.source : transaction?.verb}</Cell>
                          <Cell>
                            {transaction?.failed ? (
                              <Icon name="remove circle" color="red" onClick={() => toggle(transaction?.input)} />
                            ) : (
                              <Icon name="check circle" color="green" />
                            )}
                          </Cell>
                          <Cell>{transaction?.responseCode}</Cell>
                          <Cell>{transaction?.errorCode && replaceErrors(transaction?.errorCode)}</Cell>
                          <Cell>{transaction?.errorCode && splitErrors(transaction?.errorCode)}</Cell>

                          <Cell>{moment(transaction?.timestamp).format("yyyy-MM-DDTHH:mm:ss")}</Cell>
                        </Row>
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <Pagination onChange={(page) => updatePage(page)} number={pageState?.number} pages={pageState?.pages} />
            <Modal open={open} onClose={toggle} size="small">
              <Modal.Header> {t("transactions.table.modal.title")}</Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <Description>
                    <ReactJson src={input} displayDataTypes={false} />
                  </Description>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button size="small" onClick={toggle}>
                  {t("transactions.table.modal.close")}
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        )
      )}
    </>
  );
};

export default Transactions;
