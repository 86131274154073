/**
 * Create a list of unique origins values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createOriginOptions = () => {
  return [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Veridass",
      value: "VERIDASS",
    },
    {
      label: "POI",
      value: "POI",
    },
    {
      label: "BT",
      value: "BT",
    },
    {
      label: "DIV",
      value: "DIV",
    },
  ];
};

/**
 * Create a list of unique model_name values for a particular make.
 * Filter out undefined values as they are pretty common.
 *
 * @param actors: list of actor objects containing make, model, type tuples
 * @returns {{label: *, value: *}[]}
 */
export const createActorOptions = (actors) => {
  const result = actors
    .filter((actor) => actor !== undefined)
    .map((actor) => {
      return {
        value: actor.bnb,
        label: `${actor.bnb} - ${actor.name}`,
      };
    });
  return [{ label: "All", value: "all" }, ...result];
};
