import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { NumericFormat } from "react-number-format";

import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";

import { Form, Formik } from "formik";
import Fieldset from "src/components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "src/components/common/form/SelectField";
import DatePickerField from "src/components/common/form/DatePickerField/DatePickerField";
import Button from "src/components/common/ui/Button";
import searchFormSchema from "./searchFormSchema";
import { addYears, format } from "date-fns";
import { createOriginOptions } from "./dropdownOptions";
import Pagination from "src/components/common/ui/Pagination";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const initialSearchValues = {
  origin: "All",
  startDate: new Date(),
  endDate: new Date(),
};

const isDecimalNumber = (value) => {
  const num = Number(value);
  return !Number.isInteger(num);
};

export default function Metrics() {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [metricsResp, setMetricsResp] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [searchValues, setSearchValues] = useState(initialSearchValues);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);

  const minLoadDate = addYears(new Date(), -5);

  const maxLoadDate = new Date();
  const { accessToken } = useContext(AuthContext);

  const api = new ApiService();

  const handleSubmit = async (val) => {
    try {
      setSearchValues(val);
      await searchMetrics(val, page);
    } catch (e) {
      console.log(e);
    }
  };
  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
  };

  const searchMetrics = async (values, page) => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.searchMetrics(accessToken, values, page);
      setMetricsResp(resp.data);
      setSubmitting(false);
      setTotalPages(resp.page || 1);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };

  const transformDatesForCsv = (values) => {
    const startD = format(values.startDate, "yyyy-MM-dd");
    const endD = format(values.endDate, "yyyy-MM-dd");
    return { ...values, startDate: startD, endDate: endD };
  };

  const getMetricCsv = async () => {
    try {
      setSubmitting(true);

      await api.getMetricCsv(accessToken, transformDatesForCsv(searchValues));
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };

  const updatePage = async (page) => {
    setPage(page);
    await searchMetrics(searchValues, page);
  };

  useEffect(() => {
    searchMetrics(searchValues, 1); // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation />
      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={searchFormSchema(t)}>
        {({
          values,
          resetForm,
          isSubmitting,
          /* and other goodies */
        }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="origin"
                      label={t("metrics.form.label.origin")}
                      value={values.origin}
                      options={createOriginOptions()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDate"
                      label={t("metrics.form.label.startDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDate"
                      label={t("metrics.form.label.endDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                </Flex>
                <Flex>
                  <CsvButtonWrapper>
                    {metricsResp && metricsResp.length !== 0 && (
                      <Button type={"button"} onClick={getMetricCsv}>
                        <Icon name="external alternate" /> {t("navigation.actions.csv")}
                      </Button>
                    )}
                  </CsvButtonWrapper>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttontype="secondary"
                      type="button"
                      onClick={() => {
                        resetForm();
                        clearSearchValues();
                      }}
                    >
                      <ClearIcon />
                      {t("metrics.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit" onClick={() => setPage(1)}>
                      <SearchIcon />
                      {t("metrics.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        metricsResp && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("metrics.table.header.insuranceActorBNB")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.insurerName")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.recordNr")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.rejectionsNr")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.rejectionsPercentage")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.delay")}</HeaderCell>
                  <HeaderCell width="1">{t("metrics.table.header.sentToVeridass")}</HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {metricsResp.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("policy.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  metricsResp.map((metric, index) => (
                    <Row key={index}>
                      <Cell>{metric?.bnb}</Cell>
                      <Cell>{metric?.name}</Cell>
                      <Cell textAlign={"right"}>
                        <NumericFormat
                          value={metric?.metrics?.records}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={isDecimalNumber(metric?.metrics?.records)}
                        />
                      </Cell>
                      <Cell textAlign={"right"}>
                        <NumericFormat
                          value={metric?.metrics?.rejections}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={isDecimalNumber(metric?.metrics?.rejections)}
                        />
                      </Cell>
                      <Cell textAlign={"right"}>
                        <NumericFormat
                          value={metric?.metrics?.rejectionsPercentage}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={isDecimalNumber(metric?.metrics?.rejectionsPercentage)}
                        />
                      </Cell>
                      <Cell textAlign={"right"}>
                        <NumericFormat
                          value={metric?.metrics?.delay}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={isDecimalNumber(metric?.metrics?.delay)}
                        />
                      </Cell>
                      <Cell textAlign={"right"}>
                        <NumericFormat
                          value={metric?.metrics?.sentToVeridass}
                          displayType={"text"}
                          thousandSeparator={" "}
                          decimalSeparator={","}
                          decimalScale={2}
                          fixedDecimalScale={isDecimalNumber(metric?.metrics?.sentToVeridass)}
                        />
                      </Cell>
                    </Row>
                  ))
                )}
              </TableBody>
            </Table>
            <Pagination onChange={(page) => updatePage(page)} number={page} pages={totalPages} />
          </>
        )
      )}
    </>
  );
}
