import styled from "styled-components";

import { prop } from "src/theme";

const Title = styled.h2`
  display: block;
  color: ${prop("colors.lightBrandy")};
  font-size: 28px;
  font-weight: bold;
  z-index: 0;
  margin: 30px 0 0;
  width: 100%;
  @media (min-width: 700px) {
    font-size: 34px;
    margin: 40px 0 0;
  }
`;

export default Title;
