import React from "react";
import styled from "styled-components";

import { prop } from "src/theme";

import checkboxSrc from "./checkbox.svg";
import checkboxCheckedSrc from "./checkbox-checked.svg";
import checkboxDisabledSrc from "./checkbox-disabled.svg";

import PropTypes from "prop-types";

const Label = styled.label`
  color: ${prop("colors.extraDarkGrey")};
  display: flex;
  align-items: center;
  user-select: none;
`;
const LabelText = styled.span`
  padding-left: 3px;
  align-self: center;
  font-size: 1.1rem;
`;

const Box = styled.img.attrs({ src: checkboxSrc })`
  display: block;
  height: 24px;
  width: 24px;
  user-select: none;
`;

const CheckMarkWrapper = styled.span`
  display: flex;
  height: 40px;
  width: 30px;
  vertical-align: bottom;
  align-items: center;
`;

const Input = styled.input`
  width: 0;
  opacity: 0;

  &:checked ~ ${Box} {
    content: url(${checkboxCheckedSrc});
  }
  &:disabled ~ ${Box} {
    content: url(${checkboxDisabledSrc});
  }
`;

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  name: PropTypes.string,
};

const Checkbox = ({ id, name, label, ...props }) => (
  <Label htmlFor={id}>
    <CheckMarkWrapper>
      <Input name={name} id={id} type="checkbox" {...props} />
      <Box checked={props.checked} />
    </CheckMarkWrapper>
    <LabelText>{label}</LabelText>
  </Label>
);

Checkbox.propTypes = propTypes;
export default Checkbox;
