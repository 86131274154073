import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape({
    licensePlate: yup.string("Not a string.").matches(/^[a-zA-Z0-9]+$/, t("div.form.validations.licensePlate.regex")),
    vin: yup
      .string("Not a string.")
      .min(6, t("div.form.validations.vin.min"))
      .matches(/^[a-zA-Z0-9]+$/, t("div.form.validations.vin.regex")),
    policyNumber: yup.string("Not a string.").min(2, t("div.form.validations.policyNumber.min")),
    startDateTime: yup.date().required(),
    endDateTime: yup
      .date()
      .required()
      .when("startDateTime", (eventstartDateTime, schema) => eventstartDateTime && schema.min(eventstartDateTime)),
  });
};
