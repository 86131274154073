import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table/Table";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TableSubHeader = styled.div`
  font-weight: bold;
`;
const TitleCell = styled(HeaderCell)`
  font-size: large;
`;

export default function InsuredObjectTable({ insuredObject }) {
  const { t } = useTranslation();
  return (
    <Table fixed>
      <TableHeader>
        <Row>
          <TitleCell colSpan="4" textAlign={"center"}>
            {t("policyDetails.table.insuredObject.title")}
          </TitleCell>
        </Row>
      </TableHeader>
      <TableBody>
        <Row key={0}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.plate")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.licensePlate}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.riskNr")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.riskNumber}</Cell>
        </Row>
        <Row key={1}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.vin")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.vin}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.transactionTimestamp")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.transactionTimestamp}</Cell>
        </Row>
        <Row key={2}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.vinUnifier")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.vinUnifier}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.updateByVeridass")}</TableSubHeader>
          </Cell>
          <Cell>
            {insuredObject?.insuredObject?.updateByVeridass && String(insuredObject?.insuredObject?.updateByVeridass)}
          </Cell>
        </Row>
        <Row key={3}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.make")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.makeName}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.poiId")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.identifier}</Cell>
        </Row>
        <Row key={4}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.model")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.modelName}</Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.type")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.type}</Cell>
        </Row>
        <Row key={5}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.insuredObject.subHeaders.categoryCode")}</TableSubHeader>
          </Cell>
          <Cell>{insuredObject?.insuredObject?.vehicleCategoryCode}</Cell>
          <Cell></Cell>
          <Cell></Cell>
        </Row>
      </TableBody>
    </Table>
  );
}
