import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import Subtitle from "src/components/common/ui/Subtitle";

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const Right = styled.div`
  text-align: -webkit-right;
`;

const FailureCounts = ({ failureCounts }) => {
  const { t } = useTranslation();

  const splitErrors = (errors) => {
    const codes = errors.split(";"); // eslint-disable-next-line
    const errorMessages = codes.map((item) => {
      if (item) {
        return <p>{t(`errorCodes.${item}`)}</p>;
      }
    });
    return errorMessages;
  };

  const replaceErrors = (codes) => {
    if (codes.includes(";")) {
      return codes.replace(/;/g, " ");
    } else {
      return codes;
    }
  };
  return (
    <>
      <Subtitle>{t("insurerMetrics.table.failure.title")}</Subtitle>
      <Table>
        <TableHeader>
          <Row>
            <HeaderCell width="1">{t("insurerMetrics.table.failure.header.operation")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.failure.header.httpVerb")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.failure.header.httpCode")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.failure.header.functionalErrorCode")}</HeaderCell>
            <HeaderCell width="3">{t("insurerMetrics.table.failure.header.errorMessage")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.failure.header.failures")}</HeaderCell>
          </Row>
        </TableHeader>

        <TableBody>
          {failureCounts.length === 0 ? (
            <Row textAlign={"center"}>
              <Cell colSpan={11}>
                <NoData>{t("insurerMetrics.form.noData")}</NoData>
              </Cell>
            </Row>
          ) : (
            failureCounts?.map((transaction, index) => {
              return (
                <>
                  <Row key={index}>
                    <Cell>
                      {transaction.endpoint
                        ? lodash.truncate(transaction.endpoint, { length: 60 })
                        : transaction.operation
                        ? lodash.truncate(transaction.operation, { length: 60 })
                        : t("insurerMetrics.table.failure.otherFailure")}
                    </Cell>
                    <Cell>{transaction.verb ? transaction.verb : transaction.source}</Cell>
                    <Cell>{transaction?.responseCode}</Cell>
                    <Cell>{replaceErrors(transaction.errorCode)}</Cell>
                    <Cell>{splitErrors(transaction.errorCode)}</Cell>
                    <Cell>
                      <Right>{transaction?.failures}</Right>
                    </Cell>
                  </Row>
                </>
              );
            })
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default FailureCounts;
