import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "src/components/common/ui/Table";
import { prop } from "../../theme";
import ErrorMessage from "src/components/common/ui/ErrorMessage";
import Spinner from "src/components/common/ui/Spinner";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import moment from "moment";
import InputField from "src/components/common/form/InputField";

import { Form, Formik } from "formik";
import Fieldset from "src/components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "src/components/common/form/SelectField";
import DatePickerField from "src/components/common/form/DatePickerField/DatePickerField";
import Button from "src/components/common/ui/Button";
import searchFormSchema from "./searchFormSchema";
import { addYears } from "date-fns";
import { createActorOptions, createOriginOptions } from "src/helpers/helpers";
import Pagination from "src/components/common/ui/Pagination";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const iniitialSortValues = {
  label: undefined,
  order: "desc",
};
const iniitialPageValues = {
  number: 1,
  pages: 3,
};
const initialSearchValues = {
  bnb: "all",
  origin: "all",
  licensePlate: "",
  vin: "",
  endDateTime: new Date(new Date().setHours(23, 59, 59, 999)),
  startDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
};

export default function PolicySearch() {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState();
  const [searchResp, setSearchResp] = useState();
  const [actors, setActors] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const [pageState, setPageState] = useState({
    ...iniitialPageValues,
    ...location?.state?.page,
  });
  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });
  const [sortState, setSortState] = useState({
    ...iniitialSortValues,
    ...location?.state?.sort,
  });

  const minLoadDate = addYears(new Date(), -7);
  const maxLoadDate = addYears(new Date(), 1);
  const { accessToken } = useContext(AuthContext);

  const api = new ApiService();

  const handleSubmit = async (val) => {
    try {
      val.licensePlate = val.licensePlate?.toUpperCase().trim();
      val.vin = val.vin?.toUpperCase().trim();
      setSearchValues({ ...initialSearchValues, ...val });
      await searchAudit(val, pageState, sortState);
    } catch (e) {
      console.error(e);
    }
  };
  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setSortState(iniitialSortValues);
    setPageState(1);
    setError(null);
    setSearchResp(undefined);
  };

  const searchAudit = async (values, page, sort) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.searchAudit(accessToken, values, page, sort);
      setSearchResp(resp.data);
      setSubmitting(false);
      setPageState(resp?.page || iniitialPageValues);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };
  const getAuditCsv = async () => {
    try {
      setSubmitting(true);

      await api.getAuditCsv(accessToken, searchValues, sortState);
      setSubmitting(false);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  const updatePage = async (page) => {
    const newPageState = {
      ...pageState,
      number: page,
    };
    setPageState(newPageState);
    await searchAudit(searchValues, newPageState, sortState);
  };

  const getActors = async () => {
    try {
      setSubmitting(true);
      setError(null);
      const resp = await api.getActor(accessToken);
      setActors(resp.data);
      setSubmitting(false);
    } catch (e) {
      setError(e);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getActors();
      if (location.state) {
        searchAudit(searchValues, pageState, sortState);
      }
    } // eslint-disable-next-line
  }, [accessToken]);

  return (
    <>
      <Navigation />
      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={searchFormSchema(t)}>
        {({
          values,
          resetForm,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="bnb"
                      label={t("audit.form.label.bnb")}
                      value={values.bnb}
                      options={createActorOptions(actors)}
                      isDisabled={values.origin === "WEBSITE"}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <SelectField
                      name="origin"
                      label={t("audit.form.label.origin")}
                      value={values.origin}
                      options={createOriginOptions()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="licensePlate"
                      label={t("audit.form.label.plate")}
                      type="text"
                      value={values.licensePlate?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="vin"
                      label={t("audit.form.label.vin")}
                      type="text"
                      value={values.vin?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDateTime"
                      label={t("audit.form.label.startDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                      showTime
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDateTime"
                      label={t("audit.form.label.endDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                      showTime
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </FieldWrapper>
                </Flex>
                <Flex>
                  <CsvButtonWrapper>
                    {searchResp && searchResp.length !== 0 && (
                      <SearchButton type="button" onClick={getAuditCsv}>
                        <Icon name="external alternate" /> {t("navigation.actions.csv")}
                      </SearchButton>
                    )}
                  </CsvButtonWrapper>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttontype="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("policy.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit" onClick={() => setPageState(1)}>
                      <SearchIcon />
                      {t("policy.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        searchResp && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("audit.table.header.bnb")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.origin")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.plate")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.vin")}</HeaderCell>
                  <HeaderCell width="3">{t("audit.table.header.legitimateInterest")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.date")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.httpsStatus")}</HeaderCell>
                  <HeaderCell width="1">{t("audit.table.header.response")}</HeaderCell>

                  <HeaderCell width="1">{t("audit.table.header.timestamp")}</HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {searchResp.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("policy.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  searchResp.map((audit, index) => {
                    return (
                      <Row key={index}>
                        <Cell>{audit?.actor?.bnb}</Cell>
                        <Cell>{audit?.origin}</Cell>
                        <Cell>{audit?.licensePlate}</Cell>
                        <Cell>{audit?.vin}</Cell>
                        <Cell>{t(`audit.table.body.legitimateInterest.${audit?.legitimateInterest}`)}</Cell>
                        <Cell>{audit?.date}</Cell>
                        <Cell>{audit?.httpStatus}</Cell>
                        <Cell>
                          {audit?.response === "INSURED" ? (
                            <Icon name="check circle" color="green" />
                          ) : (
                            <Icon name="question circle" color="orange" />
                          )}
                        </Cell>
                        <Cell>{moment(audit?.timestamp).format("yyyy-MM-DDTHH:mm:ss")}</Cell>
                      </Row>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <Pagination onChange={(page) => updatePage(page)} number={pageState?.number} pages={pageState?.pages} />
          </>
        )
      )}
    </>
  );
}
