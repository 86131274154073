import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape(
    {
      bnb: yup
        .string("Not a string.")
        .nullable()
        .when("policyNumber", {
          is: (val) => !!val,
          then: (shcema) => yup.string(),
          otherwise: (schema) => yup.string().nullable().required(t("transactions.form.validation.policyOrActor")),
        }),
      policyNumber: yup
        .string("Not a string.")
        .min(2, t("transactions.form.validation.min"))
        .when("bnb", {
          is: (val) => !!val,
          then: (schema) => yup.string(),
          otherwise: (schema) => yup.string().required(t("transactions.form.validation.policyOrActor")),
        }),
      startDateTime: yup.date().required(),
      endDateTime: yup
        .date()
        .required()
        .when("startDateTime", (eventstartDateTime, schema) => eventstartDateTime && schema.min(eventstartDateTime)),

      failed: yup.bool(),
    },
    [["policyNumber", "bnb", "startDateTime", "endDateTime", "failed"]]
  );
};
