import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { prop } from "src/theme";

const Container = styled.div`
  display: ${(props) => (props.type === "hidden" ? "inline" : "inline-flex")};
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  color: ${prop("input.color")};
  width: ${prop("input.width")};
  height: ${(props) => (props.type === "hidden" ? 0 : prop("input.height"))};
  margin: ${prop("input.margin")};
`;
export const StyledInput = styled.input`
  flex: 1 1 auto;
  color: inherit;
  background: ${prop("colors.white")};
  border: ${(props) => prop(props.error ? "input.errorBorder" : "input.border")(props)};
  border-radius: ${(props) => (props.suffix ? prop("input.leftBorderRadius") : prop("input.borderRadius"))};
  width: 100%;
  padding: ${prop("input.padding")};
  margin: 0;
  font-size: ${prop("input.fontSize")};
  &:disabled {
    color: ${prop("colors.mediumGrey")};
    border-color: ${prop("colors.ultraLightGrey")};
  }
  &:focus {
    outline-color: ${prop("colors.darkGrey")};
    -moz-outline-color: ${prop("colors.darkGrey")};

    :-moz-focusring {
      outline-color: 2px solid ${prop("colors.darkGrey")};
    }
    ${(props) => props.error && `outline-color: ${prop("colors.brandy")(props)};`}
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;
const Prefix = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: ${prop("colors.lightBrandy")};
`;
const Suffix = styled.div`
  white-space: nowrap;
  position: relative;
  border: ${(props) => prop(props.error ? "input.errorBorder" : "input.border")(props)};
  border-radius: ${prop("input.rightBorderRadius")};
  margin-left: -1px;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  color: ${prop("colors.lightGrey")};
  font-size: 1em;
`;

export const propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  type: PropTypes.oneOf(["email", "number", "password", "search", "tel", "text", "url", "hidden"]),
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  error: PropTypes.bool,
};

export default class Input extends Component {
  static propTypes = propTypes;
  static defaultProps = {
    type: "text",
  };
  render() {
    const { prefix, suffix, ...props } = this.props;
    return (
      <Container type={props.type}>
        {prefix && <Prefix>{prefix}</Prefix>}
        <StyledInput {...this.props} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </Container>
    );
  }
}
