import axios from "axios";
import qs from "qs";
import format from "date-fns/format";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import fileDownload from "js-file-download";

/**
 * Use this to transform your payload before passing it to the backend.
 * It will parse the payload transform:
 *  - empty string, array, object, null, undefined fields will be removed
 *  - Date object to formatted string
 * @param originalPayload
 * @returns {string|*}
 */
export function transform(originalPayload) {
  let payload = clone(originalPayload);
  if (payload instanceof Date) {
    return format(payload, "yyyy-MM-dd");
  } else if (payload === null || payload === "" || (payload instanceof Object && isEmpty(payload))) {
    return undefined;
  } else if (payload instanceof Object) {
    Object.keys(payload).forEach((key) => {
      // Only keep elements that are not undefined
      const transformed = transform(payload[key]);
      if (transformed === undefined) {
        delete payload[key];
      } else {
        payload[key] = transformed;
      }
    });
    return payload;
  }
  return payload;
}

/**
 * Use this to transform the backend payload before passing it to the frontend.
 * It will parse the payload and reverse transform:
 *  - string formatted as "YYYY-MM-DD" to Date object
 * @param originalPayload
 * @returns {string|*}
 */

export function reverse(originalPayload) {
  let payload = clone(originalPayload);
  if (typeof payload === "string") {
    const possiblyDate = moment(payload, "YYYY-MM-DD", true);
    if (possiblyDate.isValid()) {
      return possiblyDate.toDate();
    }
  } else if (payload instanceof Object) {
    Object.keys(payload).forEach((key) => {
      payload[key] = reverse(payload[key]);
    });
    return payload;
  }
  return payload;
}

export class BackEndError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
    this.type = "BackEndError";
  }
  static isInstance = (error) => error?.type === "BackEndError";
}

function handleError(error) {
  console.error({ error });
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    switch (error.response.status) {
      case 401:
        throw new Error("Unauthorized");
      case 404:
        throw new Error("Not found");
      case 400:
        const errorData = error.response.data.data;
        if (errorData) {
          if (Array.isArray(errorData)) {
            if (errorData[0].message) {
              throw new BackEndError(errorData[0].message, errorData[0].code);
            }
          }
        }
        // Fallback to default error in case of the 400 is not correctly structured on the backend side
        throw new Error(errorData.message || error.response.data.message);
      default:
        throw new BackEndError(
          `Got a ${error.response.status} error with no message from the server`,
          "CRITICAL_ERROR"
        );
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    throw new Error("No response received");
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new Error(error.message);
  }
}

const baseURL = window._env_.REACT_APP_BACKEND_URL;

export default class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL,
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async get(url, config, shouldReverse = true) {
    try {
      const response = await this.api.get(url, config);
      return shouldReverse ? reverse(response.data) : response.data;
    } catch (error) {
      handleError(error);
    }
  }

  async post(url, data, config) {
    try {
      const response = await this.api.post(url, transform(data), config);
      return reverse(response.data);
    } catch (error) {
      handleError(error);
    }
  }
  async postFormData(url, data, config) {
    try {
      const response = await this.api.post(url, data, {
        ...config,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return reverse(response.data);
    } catch (error) {
      handleError(error);
    }
  }
  async put(url, data, config) {
    try {
      const payload = transform(data);
      const response = await this.api.put(url, payload, config);
      return reverse(response.data);
    } catch (error) {
      handleError(error);
    }
  }
  async patch(url, data, config = {}) {
    try {
      const headers = config.headers || {};
      const response = await this.api.patch(url, transform(data), {
        ...config,
        headers: { ...headers, "Content-Type": "application/merge-patch+json" },
      });
      return reverse(response.data);
    } catch (error) {
      handleError(error);
    }
  }
  async delete(url, config) {
    try {
      const response = await this.api.delete(url, config);
      return reverse(response.data);
    } catch (error) {
      handleError(error);
    }
  }

  async getSector(accessToken) {
    return this.get("/reporting/sector-status", {
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async searchMetrics(accessToken, values, page) {
    // Default value should not be sent
    const valueParameters = {
      ...values,
      startDate: format(values.startDate, "yyyy-MM-dd"),
      endDate: format(values.endDate, "yyyy-MM-dd"),
    };
    if (valueParameters.origin === "All") delete valueParameters.origin;

    return this.get("/reporting/sector-metrics", {
      params: { ...valueParameters, ...page },
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async searchPolicy(accessToken, values, page, sort) {
    // Default value should not be sent
    const valueParameters = {
      ...values,
      bnb: values.insuranceActorBNB,
    };
    if (valueParameters.origin === "All") delete valueParameters.origin;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;
    delete valueParameters.insuranceActorBNB;
    const pageParams = {
      page: page.number || 1,
      size: 25,
    };
    const sortObject = sort.label && { sort: `${sort.label},${sort.order}` };
    return this.get("/reporting/policy", {
      params: transform({ ...pageParams, ...valueParameters, ...sortObject }),
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async searchDivPolicy(accessToken, values, page, sort) {
    // Default value should not be sent
    const valueParameters = {
      ...values,
      bnb: values.insuranceActorBNB,
    };
    if (valueParameters.bnb === "all") delete valueParameters.bnb;
    delete valueParameters.insuranceActorBNB;
    const pageParams = {
      page: page.number || 1,
      size: 25,
    };
    const sortObject = sort.label && { sort: `${sort.label},${sort.order}` };
    return this.get("/reporting/div", {
      params: transform({ ...pageParams, ...valueParameters, ...sortObject }),
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async getActor(accessToken) {
    return this.get("/reporting/actor", {
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async getInsuredObject(accessToken, id) {
    return this.get(`/reporting/insured-object/${id}`, {
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async getPolicyCsv(accessToken, values, sort) {
    const valueParameters = {
      ...values,
      bnb: values.insuranceActorBNB,
    };
    delete valueParameters.insuranceActorBNB;
    if (valueParameters.origin === "All") delete valueParameters.origin;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;

    const sortObject = sort.label && { sort: `${sort.label},${sort.order}` };
    const resp = await this.get("/reporting/policy/csv", {
      params: transform({ ...valueParameters, ...sortObject }),
      headers: {
        Authorization: accessToken,
      },
      responseType: "arraybuffer",
    });
    fileDownload(resp, "policy-extract.csv");
  }

  async getInsuredObjectCsv(accessToken, id) {
    const resp = await this.get(`/reporting/insured-object/${id}/csv`, {
      headers: {
        Authorization: accessToken,
      },
      responseType: "arraybuffer",
    });
    fileDownload(resp, "insured-object-extract.csv");
  }

  async getMetricCsv(accessToken, values) {
    const valueParameters = {
      ...values,
    };
    if (valueParameters.origin === "All") delete valueParameters.origin;

    const resp = await this.get("/reporting/sector-metrics/csv", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
        "Content-Type": "text/csv",
      },
      responseType: "arraybuffer",
    });
    fileDownload(resp, "metrics-extract.csv");
  }

  async getCounters(accessToken) {
    return await this.get(`/reporting/counters`, {
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async getTransactionDetail(accessToken, values, page) {
    const valueParameters = { ...values };
    if (valueParameters.policyNumber === "") delete valueParameters.policyNumber;
    if (valueParameters.bnb === "") delete valueParameters.bnb;
    if (valueParameters.failed === false) delete valueParameters.failed;

    const pageParams = {
      page: page.number || 1,
      size: 25,
    };
    return this.get("reporting/transaction-detail", {
      params: { ...valueParameters, ...pageParams },
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async getTransactionsCsv(accessToken, values) {
    const valueParameters = { ...values };
    if (valueParameters.policyNumber === "") delete valueParameters.policyNumber;
    if (valueParameters.bnb === "") delete valueParameters.bnb;

    if (valueParameters.failed === false) delete valueParameters.failed;

    const resp = await this.get("/reporting/transaction-detail/csv", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
      responseType: "arraybuffer",
    });
    fileDownload(resp, "transactions-extract.csv");
  }

  async getKeyMetrics(accessToken, values) {
    const valueParameters = { ...values };
    delete valueParameters.failed;
    if (valueParameters.source === "all" || valueParameters.source === "") delete valueParameters.source;

    return this.get("/reporting/insurer-metrics/key-metrics", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
    });
  }
  async getDailyCounts(accessToken, values) {
    const valueParameters = { ...values };
    delete valueParameters.failed;
    if (valueParameters.source === "all" || valueParameters.source === "") delete valueParameters.source;

    return this.get("/reporting/insurer-metrics/daily-counts", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
    });
  }
  async getTransactionCounts(accessToken, values) {
    const valueParameters = { ...values };
    if (valueParameters.failed === false) delete valueParameters.failed;
    if (valueParameters.source === "all" || valueParameters.source === "") delete valueParameters.source;

    return this.get("/reporting/insurer-metrics/transaction-counts", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
    });
  }
  async getFailureCounts(accessToken, values) {
    const valueParameters = { ...values, failed: true };
    if (valueParameters.source === "all" || valueParameters.source === "") delete valueParameters.source;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;

    return this.get("/reporting/insurer-metrics/failure-counts", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
    });
  }
  async getFiles(accessToken, values) {
    const valueParameters = {
      ...values,
    };
    if (valueParameters.status === "all" || valueParameters.status === "") delete valueParameters.status;
    if (valueParameters.type === "all" || valueParameters.type === "") delete valueParameters.type;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;
    return this.get("reporting/files", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async searchAudit(accessToken, values, page) {
    const valueParameters = { ...values };
    if (valueParameters.licensePlate === "") delete valueParameters.licensePlate;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;
    if (valueParameters.vin === "") delete valueParameters.vin;
    if (valueParameters.origin === "all") delete valueParameters.origin;
    if (valueParameters.origin === "WEBSITE") delete valueParameters.bnb;

    const pageParams = {
      page: page.number || 1,
      size: 25,
    };
    return this.get("reporting/boolean-check-audit", {
      params: { ...valueParameters, ...pageParams },
      headers: {
        Authorization: accessToken,
      },
    }, false);
  }

  async getAuditCsv(accessToken, values) {
    const valueParameters = { ...values };
    if (valueParameters.licensePlate === "") delete valueParameters.licensePlate;
    if (valueParameters.bnb === "all") delete valueParameters.bnb;
    if (valueParameters.vin === "") delete valueParameters.vin;
    if (valueParameters.origin === "all") delete valueParameters.origin;

    const resp = await this.get("/reporting/boolean-check-audit/csv", {
      params: { ...valueParameters },
      headers: {
        Authorization: accessToken,
      },
      responseType: "arraybuffer",
    });
    fileDownload(resp, "audit-extract.csv");
  }
}
